import { LectureId } from 'modules/courses/courses/curriculum/types/lecture-interface';
import {
  LectureEmailApiInterface,
  LectureEmailEditInterface,
} from 'modules/courses/emails/types/lecture-email-inteface'
import httpClient from 'shared/utils/http-client';


export const LECTURE_EMAIL_API = '/api/dashboard/customer/membership/course/lecture'

export const createLectureEmail = (lectureId: LectureId, data: LectureEmailEditInterface) =>
  httpClient.post<LectureEmailApiInterface>(`${LECTURE_EMAIL_API}/${lectureId}/email`, data)

export const updateLectureEmail = (lectureId: LectureId, data: LectureEmailEditInterface) =>
  httpClient.put<LectureEmailApiInterface>(`${LECTURE_EMAIL_API}/${lectureId}/email`, data)

export const deleteLectureEmail = (lectureId: LectureId) =>
  httpClient.delete(`${LECTURE_EMAIL_API}/${lectureId}/email`)