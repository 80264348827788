import useUser from 'shared/hooks/use-user'
import { baseFetcher } from 'shared/utils/http-client'
import useSWR from 'swr'
import { TEMPLATES_API } from 'modules/courses/courses/curriculum/api/templates-api'
import { LectureTemplateInterface } from 'modules/courses/courses/curriculum/types/lecture-interface'

export function useLectureTemplate() {
  const { user } = useUser()

  return useSWR<LectureTemplateInterface[]>(() => {
    if (user) {
      return `${TEMPLATES_API}`
    }
  }, baseFetcher)
}
