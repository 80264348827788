import { COURSE_API } from 'modules/courses/courses/curriculum/api/course-curriculum-api'
import {
  CreateModuleInterface,
  ModuleInterface,
} from 'modules/courses/courses/curriculum/types/module-interface'
import { CourseId } from 'modules/courses/courses/types/course-interface'
import httpClient from 'shared/utils/http-client'

export const COURSE_MODULE_API = '/api/dashboard/customer/membership/module'

export const createModule = (id: CourseId, data: CreateModuleInterface) =>
  httpClient.post<ModuleInterface>(`${COURSE_API}/${id}/create-module`, data)

export const updateModule = (id: CourseId, data: CreateModuleInterface) =>
  httpClient.put<ModuleInterface>(`${COURSE_MODULE_API}/${id}`, data)

export const changeModulePosition = (id: CourseId, data: { new_position: number }) =>
  httpClient.post<ModuleInterface>(`${COURSE_MODULE_API}/${id}/change-position`, data)
