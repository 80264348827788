import CreateModuleModal from 'modules/courses/courses/curriculum/components/create-module-modal'
import { ModuleInterface } from 'modules/courses/courses/curriculum/types/module-interface'
import { CourseId } from 'modules/courses/courses/types/course-interface'
import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import EmptyListIcon from 'shared/icons/empty-list-icon'

interface CourseCurriculumEmptyListInterface {
  createModuleWithUpdate: (module: ModuleInterface) => Promise<void>
  courseId: CourseId
  disabled: boolean
}

const CourseCurriculumEmptyList = ({
  createModuleWithUpdate,
  courseId,
  disabled,
}: CourseCurriculumEmptyListInterface) => {
  const { t } = useLocoTranslation()
  return (
    <div className="flex w-full justify-center items-center flex-col gap-3 py-20 bg-white rounded-lg mt-5">
      <EmptyListIcon />
      <div className={`flex flex-col items-center`}>
        <span className={'text-darkblue text-base'}>{t('dashboard.list.empty_title')}</span>
        <span className={'text-darkblue text-base'}>{t('dashboard.list.empty_list')}</span>
      </div>
      <CreateModuleModal
        createModuleWithUpdate={createModuleWithUpdate}
        courseId={courseId}
        disabled={disabled}
        withIcon={false}
      />
    </div>
  )
}

export default CourseCurriculumEmptyList
