import Cookie from 'js-cookie'


export const setSelectedСoursesCurriculumCookie = (coursesId: number, CurriculumId: number) => {
    Cookie.set(`courses_${coursesId}_curriculum_select`, CurriculumId.toString())
}

export const getSelectedСoursesCurriculumCookie = (coursesId: number) =>
    Cookie.get(`courses_${coursesId}_curriculum_select`)

