import { COURSES_API } from 'modules/courses/courses/api/courses-api'
import {
  CourseEditApiInterface,
  CourseId,
} from 'modules/courses/courses/types/course-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useCourse(id: CourseId) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    CourseEditApiInterface
  >({ method: RequestMethodsEnum.get })

  const { data: courseData, mutate } = useSWR(() => {
    if (user) {
      return `${COURSES_API}/${id}`
    }
  }, fetcher)
  
  return { courseData, mutate }
}
