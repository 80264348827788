import {
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useRollbar } from '@rollbar/react'
import { changeLecturePosition } from 'modules/courses/courses/curriculum/api/lecture-api'
import { changeModulePosition } from 'modules/courses/courses/curriculum/api/module-api'
import CourseModule from 'modules/courses/courses/curriculum/components/course-module'
import ModuleLecture from 'modules/courses/courses/curriculum/components/module-lecture'
import { CourseCurriculumInterface } from 'modules/courses/courses/curriculum/types/curriculum-interface'
import { ModuleInterface } from 'modules/courses/courses/curriculum/types/module-interface'
import {
  getSelectedСoursesCurriculumCookie,
  setSelectedСoursesCurriculumCookie,
} from 'modules/courses/courses/curriculum/utils/cookie-utils'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { KeyedMutator } from 'swr'

function CourseCurriculumTable({
  modules,
  mutate,
  courseId,
}: {
  modules: ModuleInterface[]
  mutate: KeyedMutator<CourseCurriculumInterface>
  courseId: number
}) {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const [clonedItems, setClonedItems] = useState<ModuleInterface[] | null>(null)
  const [recentlyMovedToNewModule, setRecentlyMovedToNewModule] = useState(false)

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))
  const lastOverId = useRef<UniqueIdentifier | null>(null)
  const recentlyMovedRef = useRef(false)
  const containers = modules.map(module => module.id) as UniqueIdentifier[]
  const isSortingContainer = activeId ? containers.includes(activeId) : false
  const selectedСoursesCurriculumCookie = getSelectedСoursesCurriculumCookie(courseId)
  const rollbar = useRollbar()
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    args => {
      if (activeId && containers.includes(activeId)) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(container => {
            return containers.includes(container.id)
          }),
        })
      }
      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args)
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args)
      let overId = getFirstCollision(intersections, 'id')

      if (overId != null) {
        if (containers.includes(overId)) {
          const containerModule = modules.find(module => module.id === overId)
          if (containerModule) {
            const containerItems = containerModule.lectures
            // If a container is matched and it contains items
            if (containerItems.length > 0) {
              // Return the closest droppable within that container
              overId = closestCenter({
                ...args,
                droppableContainers: args.droppableContainers.filter(container => {
                  const lecture = containerItems.find(el => el.id === container.id)
                  return container.id !== overId && lecture
                }),
              })[0]?.id
            }
          }
        }
        lastOverId.current = overId
        return [{ id: overId }]
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedRef.current) {
        lastOverId.current = activeId
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : []
    },
    [activeId, modules],
  )

  const findContainer = (id: UniqueIdentifier) => {
    if (containers.includes(id)) {
      return id
    }
    return modules.find(module => {
      const searchLecture = module.lectures.find(lecture => lecture.id === id)
      if (searchLecture) {
        return module
      }
    })?.id
  }

  const onDragCancel = async () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been Dragged across containers
      await mutate(data => {
        if (data) {
          return {
            ...data,
            modules: clonedItems,
          }
        }
      })
    }
    setActiveId(null)
    setClonedItems(null)
  }

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    const overId = over?.id
    if (containers.includes(active.id) && overId) {
      const activeIndex = modules.findIndex(module => module.id === active.id)
      const overIndex = modules.findIndex(module => module.id === over.id)

      if (activeIndex === overIndex) return

      await mutate(data => {
        if (data) {
          return {
            ...data,
            modules: arrayMove(data.modules, activeIndex, overIndex),
          }
        }
      }, false)
      await changeModulePosition(Number(active.id), { new_position: overIndex + 1 })
    }

    const activeContainer = findContainer(active.id)
    if (!activeContainer) {
      setActiveId(null)
      return
    }

    if (overId == null) {
      setActiveId(null)
      return
    }

    const overContainer = findContainer(overId)
    if (overContainer) {
      const moduleOver = modules.find(module => module.id === overContainer)
      const moduleActive = modules.find(module => module.id === activeContainer)
      if (moduleOver && moduleActive) {
        const activeIndex = moduleActive.lectures.findIndex(lecture => lecture.id === active.id)
        const overIndex = moduleOver.lectures.findIndex(lecture => lecture.id === overId)

        if (activeIndex === -1 || overIndex === -1) {
          setActiveId(null)
          return
        }

        await mutate(data => {
          if (data) {
            return {
              ...data,
              modules: data.modules.map(module => {
                if (module.id === overContainer) {
                  return {
                    ...module,
                    lectures: arrayMove(module.lectures, activeIndex, overIndex),
                  }
                }
                return module
              }),
            }
          }
        }, false)

        if (recentlyMovedToNewModule) {
          await changeLecturePosition(Number(active.id), {
            new_position: overIndex + 1,
            module: moduleOver.id,
          })
        } else {
          await changeLecturePosition(Number(active.id), { new_position: overIndex + 1 })
        }
      }
    }
    setActiveId(null)
  }

  const onDragOver = async ({ active, over }: DragOverEvent) => {
    const overId = over?.id
    if (overId === undefined || overId === null || containers.includes(active.id)) {
      return
    }

    const overContainerId = findContainer(overId)
    const activeContainerId = findContainer(active.id)

    if (!overContainerId || !activeContainerId) {
      return
    }

    if (activeContainerId !== overContainerId) {
      setRecentlyMovedToNewModule(true)
      const activeModule = modules.find(module => module.id === activeContainerId)
      const overModule = modules.find(module => module.id === overContainerId)
      const activeItems = activeModule?.lectures
      const overItems = overModule?.lectures
      const overIndex = overItems?.findIndex(el => el.id === overId)
      const activeIndex = activeItems?.findIndex(el => el.id === active.id)
      let newIndex: number

      if (containers.includes(overId) && overItems) {
        newIndex = overItems.length + 1
      } else {
        const isBelowOverItem =
          over &&
          active.rect.current.translated &&
          active.rect.current.translated.top > over.rect.top + over.rect.height

        const modifier = isBelowOverItem ? 1 : 0
        if (overItems) {
          newIndex = overIndex && overIndex >= 0 ? overIndex + modifier : overItems.length + 1
        }
      }

      recentlyMovedRef.current = true
      await mutate(data => {
        if (data) {
          return {
            ...data,
            modules: data.modules.map(module => {
              if (module.id === activeContainerId) {
                return {
                  ...module,
                  lectures: module.lectures.filter(item => item.id !== active.id),
                }
              }
              if (module.id === overContainerId) {
                const activeModule = modules.find(module => module.id === activeContainerId)
                if (activeModule && typeof activeIndex === 'number') {
                  return {
                    ...module,
                    lectures: [
                      ...module.lectures.slice(0, newIndex),
                      activeModule.lectures[activeIndex],
                      ...module.lectures.slice(newIndex, overModule?.lectures.length),
                    ],
                  }
                }
              }
              return module
            }),
          }
        }
      }, false)
    }
  }

  function onSelectCoursesCurriculum(CurriculumId: number) {
    setSelectedСoursesCurriculumCookie(courseId, CurriculumId)
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedRef.current = false
    })
    modules.forEach(module => {
      module.lectures.forEach(lecture => {
        if (!lecture) {
          rollbar.error('Found undefined lecture error', {
            modules: modules,
            lectures: module.lectures,
          })
        }
      })
    })
  }, [modules])

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id)
        setClonedItems(modules)
        setRecentlyMovedToNewModule(false)
      }}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <SortableContext items={modules} strategy={verticalListSortingStrategy}>
        <div className="flex flex-col mt-5 gap-5">
          {modules.map(module => (
            <CourseModule
              key={module.id}
              module={module}
              mutate={mutate}
              childrenCount={module.lectures.length}
              openDefault={module.id === Number(selectedСoursesCurriculumCookie)}
              onSelect={onSelectCoursesCurriculum}
            >
              <SortableContext items={module.lectures} strategy={verticalListSortingStrategy}>
                {module.lectures.map((lecture, index) => {
                  return (
                    <ModuleLecture
                      key={lecture.id}
                      disabled={isSortingContainer}
                      lecture={lecture}
                      moduleId={module.id}
                      mutate={mutate}
                      isLastChild={index === module.lectures.length - 1}
                    />
                  )
                })}
              </SortableContext>
            </CourseModule>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  )
}

export default CourseCurriculumTable
