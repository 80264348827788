import { createModule } from 'modules/courses/courses/curriculum/api/module-api'
import {
  CreateModuleErrorsInterface,
  CreateModuleInterface,
  ModuleInterface,
} from 'modules/courses/courses/curriculum/types/module-interface'
import { CourseId } from 'modules/courses/courses/types/course-interface'
import React, { useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import CreateModal from 'shared/components/create-modal'
import FormInput from 'shared/components/form-input'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AddIcon from 'shared/icons/add-icon'
import { fetchWithFieldErrors } from 'shared/utils/fetch-with-errors'

const defaultErrors: CreateModuleErrorsInterface = {
  fields: {
    name: '',
  },
}
const defaultValues: CreateModuleInterface = {
  name: '',
}

interface CreateModuleModalInterface {
  createModuleWithUpdate: (module: ModuleInterface) => Promise<void>
  courseId: CourseId
  disabled: boolean
  withIcon?: boolean
}

const CreateModuleModal = ({
  createModuleWithUpdate,
  courseId,
  disabled,
  withIcon = true,
}: CreateModuleModalInterface) => {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  const [errors, setErrors] = useState(defaultErrors)
  const afterLeave = () => {
    setErrors(defaultErrors)
  }
  const onClose = () => setOpened(false)

  const onCreate = async (data: CreateModuleInterface) => {
    await fetchWithFieldErrors(async () => {
      const response = await createModule(courseId, data)
      await createModuleWithUpdate(response.data)
    }, setErrors)
  }

  return (
    <>
      <PrimaryButton
        disabled={disabled}
        className="flex justify-between items-center"
        onClick={() => setOpened(true)}
      >
        <AddIcon className={`${withIcon ? 'block' : 'lg:hidden'} block`} />
        <span className="whitespace-nowrap">
          {t('dashboard.course.add_module_modal.trigger_title')}
        </span>
      </PrimaryButton>
      <CreateModal
        opened={opened}
        onClose={onClose}
        afterLeave={afterLeave}
        defaultValues={defaultValues}
        caption={t('dashboard.course.module_title')}
        creatable={[
          {
            field: 'name',
            onRender: (value, onChange) => (
              <FormInput
                key={'course-module-create'}
                label={t('global.name')}
                value={value}
                required
                onChange={e => onChange(e.target.value)}
                error={errors.fields.name}
              />
            ),
          },
        ]}
        onCreate={onCreate}
        requiredFields={['name']}
      />
    </>
  )
}

export default CreateModuleModal
