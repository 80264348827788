import { COURSE_MODULE_API } from 'modules/courses/courses/curriculum/api/module-api'
import {
  CreateLectureInterface,
  EditLectureInterface,
  LectureInterface,
} from 'modules/courses/courses/curriculum/types/lecture-interface'
import {
  FetcherType,
  RequestMethodsEnum,
} from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import httpClient from 'shared/utils/http-client'

export const LECTURE_API = '/api/dashboard/customer/membership/lecture'

export const activateLecture = (
  update: 'deactivate' | 'activate',
  id: number,
  fetcher: FetcherType<RequestMethodsEnum.post, LectureInterface>,
) => fetcher(`${LECTURE_API}/${id}/${update}`)

export const duplicateLecture = (
  id: number,
  fetcher: FetcherType<RequestMethodsEnum.post, LectureInterface>,
) => fetcher(`${LECTURE_API}/${id}/duplicate`)

export const removeLecture = (id: number, fetcher: FetcherType<RequestMethodsEnum.delete>) =>
  fetcher(`${LECTURE_API}/${id}`)

export const createLecture = (id: number, data: CreateLectureInterface) =>
  httpClient.post<LectureInterface>(`${COURSE_MODULE_API}/${id}/create-lecture`, data)

export const changeLecturePosition = (
  id: number,
  data: { new_position: number; module?: number },
) => httpClient.post<LectureInterface>(`${LECTURE_API}/${id}/change-position`, data)

export const updateLecture = (
  id: number,
  data: EditLectureInterface,
  fetcher: FetcherType<RequestMethodsEnum.put, LectureInterface>,
) => fetcher(`${LECTURE_API}/${id}`, data)
