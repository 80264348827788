import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { COURSE_MODULE_API } from '../api/module-api'
import { ModuleId, QuizInterface } from '../types/module-interface'

export const useModuleApi = () => {
  const { fetcher: postFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.post,
    QuizInterface
  >({
    method: RequestMethodsEnum.post,
  })

  const { fetcher: deleteFetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.delete>({
    method: RequestMethodsEnum.delete,
  })

  return {
    creatingQuizForModule: (id: ModuleId) => postFetcher(`${COURSE_MODULE_API}/${id}/create-quiz`),
    removeModuleWithLectures: (moduleId: ModuleId) =>
      deleteFetcher(`${COURSE_MODULE_API}WithLectures/${moduleId}`),
  }
}
