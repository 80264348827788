import { LectureInterface } from 'modules/courses/courses/curriculum/types/lecture-interface'
import { createLectureEmail } from 'modules/courses/emails/api/lecture-email-api'
import { LectureEmailEditInterface } from 'modules/courses/emails/types/lecture-email-inteface'
import EmailTemplateSelector from 'modules/email/components/email-template-selector/email-template-selector'
import { useGetEmailTemplates } from 'modules/email/hooks/use-get-email-templates'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import CreateModal, { CreateModalProps } from 'shared/components/create-modal'
import { useEmailOptions } from 'shared/components/dashboard-mail-editor/hooks/use-email-options'
import { EmailBaseInterface } from 'shared/components/dashboard-mail-editor/types/email-interface'
import FormInput from 'shared/components/form-input'
import SenderEmailSelect from 'shared/components/sender-email-select'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useUpdateLectureEmail } from '../../../../emails/hooks/use-update-lecture-email'
import { CourseId } from '../../../types/course-interface'
import { useUpdateLectureData } from '../../hooks/use-update-lecture-data'
import { CourseLectureEmailErrorResponseContentInterface } from '../../types/course-lecture-email-error-response-interface'
import { getCourseLectureEmailUrl } from '../../utils/get-course-lecture-email-url'

const defaultErrors: CreateModuleErrorsInterface = {
  fromEmail: '',
  fromName: '',
  subject: '',
  previewText: '',
  useNewEditor: '',
  template: '',
  html: '',
  body: '',
}

const defaultValues: CreateModuleInterface = {
  fromEmail: '',
  fromName: '',
  subject: '',
  previewText: '',
  useNewEditor: false,
}

export type CreateModuleInterface = EmailBaseInterface & {
  template?: number
}

export type CreateModuleErrorsInterface = Record<keyof CreateModuleInterface, string>

interface LectureEmailActionProps extends Pick<CreateModalProps<CreateModuleInterface>, 'onClose'> {
  lecture: LectureInterface
  isOpened: boolean
}

export function LectureEmailAction({ lecture, isOpened, onClose }: LectureEmailActionProps) {
  const { t } = useLocoTranslation()
  const router = useRouter()
  const courseId = Number(router.query.courseId) as CourseId

  const [errors, setErrors] = useState(defaultErrors)
  const [values, setValues] = useState(defaultValues)

  const { templates } = useGetEmailTemplates()
  const { data: options } = useEmailOptions()

  const updateLectureData = useUpdateLectureData(courseId)
  const updateLectureEmail = useUpdateLectureEmail(lecture.id)

  const afterLeave = () => {
    setErrors(defaultErrors)
  }

  const onCreate = async (data: CreateModuleInterface) => {
    try {
      const createData: LectureEmailEditInterface = {
        drippingAccessMailing: {
          currentEmail: {
            subject: data.subject,
            previewText: data.previewText,
            fromName: data.fromName,
            fromEmail: data.fromEmail,
            useNewEditor: data.useNewEditor,
            ...(data.template ? { template: data.template } : {}),
          },
        },
      }
      await createLectureEmail(lecture.id, createData)
      await updateLectureData(false)
      await updateLectureEmail(false)
      router.push(
        getCourseLectureEmailUrl({
          courseId,
          lectureId: lecture.id,
        }),
      )
    } catch (e) {
      if (e instanceof BadRequest) {
        const errors = e.errors as CourseLectureEmailErrorResponseContentInterface
        const fields = errors.fields
        if (!Array.isArray(fields)) {
          const emailErrors = fields.drippingAccessMailing?.currentEmail
          setErrors(prev => ({
            ...prev,
            body: emailErrors?.body?.join('') || '',
            fromEmail: emailErrors?.fromEmail?.join('') || '',
            fromName: emailErrors?.fromName?.join('') || '',
            subject: emailErrors?.subject?.join('') || '',
            previewText: emailErrors?.previewText?.join('') || '',
            template: emailErrors?.template?.join('') || '',
          }))
        }
        if (e.errors.common) {
          const commonErrors = e.errors.common
          setErrors(prev => {
            return {
              ...prev,
              common: commonErrors,
            }
          })
        }
      }
      throw e
    }
  }

  useEffect(() => {
    if (options) {
      setValues({
        ...values,
        fromEmail: values.fromEmail || options?.senderEmailAddress,
        fromName: values.fromName || options?.senderName,
      })
    }
  }, [options])

  return (
    <CreateModal
      opened={isOpened}
      isPrefetching={!options}
      onClose={onClose}
      afterLeave={afterLeave}
      defaultValues={values}
      caption={t('global.email')}
      requiredFields={['subject', 'fromName', 'fromEmail']}
      creatable={[
        {
          field: 'subject',
          onRender: (value, onChange) => (
            <FormInput
              required
              key="newsletter-subject"
              label={t('dashboard.email.subject')}
              value={value}
              onChange={e => onChange(e.target.value)}
              maxLength={256}
              error={errors.subject}
            />
          ),
        },
        {
          field: 'fromName',
          onRender: (value, onChange) => (
            <FormInput
              required
              key="sender-name"
              label={t('dashboard.email.sender_name')}
              value={value}
              onChange={e => onChange(e.target.value)}
              maxLength={256}
              error={errors.fromName}
            />
          ),
        },
        {
          field: 'fromEmail',
          onRender: (value, onChange) => (
            <SenderEmailSelect
              required
              key="sender-email"
              isPreFetching={!options}
              onChange={onChange}
              value={value}
              error={errors.fromEmail}
            />
          ),
        },
        {
          field: 'template',
          onRender: (_, __, state, onChangeState) => (
            <EmailTemplateSelector
              key="template-create"
              useNewEditor={!!state.useNewEditor}
              templateId={state.template}
              onChange={({ useNewEditor, template }) =>
                onChangeState({ ...state, template, useNewEditor })
              }
              templates={templates}
            />
          ),
        },
      ]}
      onCreate={onCreate}
    />
  )
}
