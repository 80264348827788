import React from 'react'

function StudentIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 19 19"
      fill="#5C5F62"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3608 9.44301L13.5162 10.0029C12.3305 10.3653 11.1118 10.53 9.89287 10.53C8.67418 10.53 7.45532 10.3653 6.26946 10.0029L4.39184 9.44301C4.35899 9.44301 4.29304 9.41016 4.26019 9.41016C3.79905 10.2665 3.56836 11.2218 3.56836 12.2758C3.56836 15.7344 6.36829 18.5342 9.82674 18.5342C13.2854 18.5342 16.0851 15.7342 16.0851 12.2758C16.0856 11.2547 15.8551 10.2665 15.3609 9.44305C15.394 9.44305 15.394 9.44305 15.3609 9.44305L15.3608 9.44301Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.23129 3.89036L4.06404 4.74671V7.31594C4.06404 7.84301 4.39352 8.30416 4.92039 8.46892L6.76492 9.02885C8.80706 9.62163 10.9811 9.62163 13.0233 9.02885L14.8678 8.46892C15.3618 8.30418 15.7242 7.84302 15.7242 7.31594V4.71389L17.404 4.2199V6.82195H17.3052C17.1735 6.82195 17.0745 6.92075 17.0745 7.05263V8.27131C17.0745 8.40296 17.1733 8.50199 17.3052 8.50199H17.3381L16.9429 9.25952C16.8769 9.42426 16.9757 9.589 17.1405 9.589H18.3263C18.491 9.589 18.6226 9.3914 18.5238 9.25952L18.1287 8.50199H18.1615C18.2932 8.50199 18.3922 8.40319 18.3922 8.27131V7.05263C18.3922 6.92097 18.2934 6.82195 18.1615 6.82195H18.0627V3.98921L18.3591 3.89041C18.8862 3.72567 18.8862 3.001 18.3591 2.83625L10.9814 0.629584C10.5862 0.530784 10.2238 0.464844 9.82841 0.464844C9.43321 0.464844 9.07087 0.530784 8.67544 0.629584L1.23124 2.83649C0.70417 3.00123 0.70417 3.7259 1.23124 3.89042L1.23129 3.89036Z"
        fill="white"
      />
    </svg>
  )
}

export default StudentIcon
