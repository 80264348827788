import useUser from 'shared/hooks/use-user'
import { baseFetcher } from 'shared/utils/http-client'
import useSWR from 'swr'
import { SALES_FUNNELS_API } from 'modules/courses/courses/api/sales-funnels-api'
import { SalesFunnelInterface } from 'modules/courses/courses/types/sales-funnel-interface'

export function useSalesFunnels() {
  const { user } = useUser()

  return useSWR<SalesFunnelInterface[]>(() => {
    if (user) {
      return SALES_FUNNELS_API
    }
  }, baseFetcher)
}
