import { CoursesLoco } from 'modules/courses/constants/courses-loco'
import CourseCurriculumEmptyList from 'modules/courses/courses/curriculum/components/course-curriculum-empty-list'
import CourseCurriculumSkeleton from 'modules/courses/courses/curriculum/components/course-curriculum-skeleton'
import CourseCurriculumTable from 'modules/courses/courses/curriculum/components/course-curriculum-table'
import CreateModuleModal from 'modules/courses/courses/curriculum/components/create-module-modal'
import CreateStudentModal from 'modules/courses/courses/curriculum/components/create-student-modal'
import { useCourseCurriculum } from 'modules/courses/courses/curriculum/hooks/use-course-curriculum'
import { ModuleInterface } from 'modules/courses/courses/curriculum/types/module-interface'
import { useCourse } from 'modules/courses/courses/hooks/use-course'
import { CourseId } from 'modules/courses/courses/types/course-interface'
import { GetStaticPaths, NextPage } from 'next'
import { useRouter } from 'next/router'
import Breadcrumbs, { BreadcrumbsData } from 'shared/components/breadcrumbs'
import { PrimaryButton } from 'shared/components/button'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import EyeIcon from 'shared/icons/eye-icon'
import SettingsIcon from 'shared/icons/settings-icon'
import StudentIcon from 'shared/icons/student-icon'
import { getBaseServerSideProps } from 'shared/utils/get-base-server-side-props'

const Curriculum: NextPage = () => {
  const { t } = useLocoTranslation()
  const router = useRouter()
  const courseId = Number(router.query.courseId) as CourseId

  const { courseCurriculumData, mutate } = useCourseCurriculum(courseId)
  const { courseData } = useCourse(courseId)

  const breadcrumbsData: BreadcrumbsData[] = [
    { caption: t('dashboard.courses.title_plural'), href: '/courses', isStatic: true },
    { caption: courseData?.name || '' },
  ]

  const createModuleWithUpdate = async (newModule: ModuleInterface) => {
    await mutate(data => {
      if (data) {
        return {
          ...data,
          modules: [...data.modules, newModule],
        }
      }
    }, false)
  }

  return (
    <div className="flex flex-col">
      <div className="flex md:flex-row flex-col justify-between items-center lg:gap-5 gap-4">
        <Breadcrumbs data={breadcrumbsData} isFetching={!courseData} />
        <div className="flex flex-wrap md:flex-nowrap gap-2.5 lg:gap-4">
          <CreateStudentModal
            courseCurriculumData={courseCurriculumData}
            courseId={courseId}
            disabledButton={courseData?.isDeactivated}
          />
          <PrimaryButton
            href={`/courses/students?filter=%7B%22course%22%3A${courseId}%7D`}
            aria-disabled={!courseCurriculumData}
          >
            <StudentIcon />
            <span className="xl:block hidden">{t('dashboard.students.title_plural')}</span>
          </PrimaryButton>
          <PrimaryButton
            href={courseCurriculumData?.loginLinkUrl}
            target={'_blank'}
            aria-disabled={!courseCurriculumData}
          >
            <EyeIcon fill="white" />
            <span className="xl:block hidden">{t('dashboard.curriculum.preview_course')}</span>
          </PrimaryButton>
          <PrimaryButton href={`/courses/${courseId}`} aria-disabled={!courseCurriculumData}>
            <SettingsIcon fill="white" />
            <span className="xl:block hidden">{t('global.settings')}</span>
          </PrimaryButton>
        </div>
      </div>
      {courseCurriculumData ? (
        courseCurriculumData.modules.length > 0 ? (
          <>
            <CourseCurriculumTable
              modules={courseCurriculumData.modules}
              mutate={mutate}
              courseId={courseId}
            />
            <div className="mt-10 self-center">
              <CreateModuleModal
                createModuleWithUpdate={createModuleWithUpdate}
                courseId={courseId}
                disabled={!courseCurriculumData}
              />
            </div>
          </>
        ) : (
          <CourseCurriculumEmptyList
            createModuleWithUpdate={createModuleWithUpdate}
            courseId={courseId}
            disabled={!courseCurriculumData}
          />
        )
      ) : (
        <CourseCurriculumSkeleton />
      )}
    </div>
  )
}

export const getStaticProps = getBaseServerSideProps(CoursesLoco, 'header.top_menu.courses')

export const getStaticPaths: GetStaticPaths<{ courseId: string }> = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export default Curriculum
